// Tanner Fry
// tannerf1101@yahoo.com
// API calls for weather dashboard.

import { getCorrectDomain } from "../AppSettings";

// Function to get geocoding data from the OpenWeatherMap API for a given location string.
// Note: You can pass just city names or city, state, country: {city name},{state code},{country code}.
// See: https://openweathermap.org/api/geocoding-api
// NOTE: I started implemented the weather API calls on our server instead of directly from the client. This should be more secure,  
//       prevent our API keys from being exposed, allow us to add more functionality to the API calls, allow us to cache data, and 
//       allow us to bypass CORS.
export const APIGetGeoLocation = (locationString, limit=1) => {
    return fetch(getCorrectDomain() + '/api/weather/geo', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
        },
        body: JSON.stringify({
            locationString: locationString,
            limit: limit
        })
    }).then(function (response) {        
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(response);
    }).then(function (data) {
        // console.log("Fetched geocoding data for " + locationString + " : " + JSON.stringify(data));
        return data[0];
    }).catch(function (error) {
        console.warn('Something went wrong.', error);
    });
}

// Function to get weather data from the OpenWeatherMap API for a given lat/long.
// NOTE: I started implemented the weather API calls on our server instead of directly from the client. This should be more secure,  
//       prevent our API keys from being exposed, allow us to add more functionality to the API calls, allow us to cache data, and 
//       allow us to bypass CORS.
export const APIGetWeatherData = (lat, lon) => {
    return fetch(getCorrectDomain() + '/api/weather', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
        },
        body: JSON.stringify({
            lat: lat,
            lon: lon
        })
    }).then(function (response) {        
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(response);
    }).then(function (data) {
        // console.log("Fetched weather data for (" + lat + ", " + lon + "): " + JSON.stringify(data));
        return data[0];
    }).catch(function (error) {
        console.warn('Something went wrong.', error);
    });
}
// Tell server to update images from OMW API
export const APIGetWeatherRadarData = (layer, z, y, x) => {
    return fetch(getCorrectDomain() + '/api/weather/radar/update', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
        },
        body: JSON.stringify({
            layer: layer,
            z: z,
            y: y,
            x: x
        })
    }).then(function (response) {        
        if (response.ok) {
            console.log("APIGetWeatherRadarData response: ", response);
            return response.json();
        }
        return Promise.reject(response);
    }).then(function (data) {
        // console.log("Fetched weather data for (" + lat + ", " + lon + "): " + JSON.stringify(data));
        return data[0];
    }).catch(function (error) {
        console.warn('Something went wrong.', error);
    });
}