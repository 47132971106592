// Tanner Fry
// tannerf1101@yahoo.com
// Component for the Fishing sub-page of the Hobbies page.

import { useState } from "react";
import { TabButton } from "../Button";
import HobbiesFishingLicensesAndRegulationsTable from "./HobbiesFishingLicensesAndRegulations";
import HobbiesHuntingLicensesAndRegulationsTable from "./HobbiesHuntingLicensesAndRegulations";

interface IHobbiesHuntingSeasons {
    contentHeight: number;
}

function HobbiesHuntingSeasons({contentHeight}: IHobbiesHuntingSeasons) {

    return (
        <div className="hunting-seasons" style={{height: contentHeight}}>
            <h2>Hunting Seasons</h2>
            <p>
                While regulations vary between the states, typically the spring hunting season starts in April and goes to May while
                the fall hunting season can start as early as August and go to October.
            </p>
            <HobbiesHuntingLicensesAndRegulationsTable />
        </div>
    );
}

export default HobbiesHuntingSeasons;