// Tanner Fry

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function RouteNotFound() {
    const navigate = useNavigate();

    useEffect(() => {
        
    }, []);

    return (
        <>
            <div className='not-found-container'>
                <span className='title'>404</span>
                <div className='divider'></div>
                <span className='text'>Not found.</span>
            </div>
        </>
    );
}


export default RouteNotFound;