import { useEffect, useState } from "react";

interface ILoadingProps {
    size?: number;
    withBackground?: boolean;
}

function Loading({size, withBackground}: ILoadingProps) {
    const [loadingText, setLoadingText] = useState("Loading");

    // Change loading text every 1 second
    useEffect(() => {
        if (!withBackground) {
            const interval = setInterval(() => {
                if (loadingText === "Loading...") {
                    setLoadingText("Loading");
                } else {
                    setLoadingText(loadingText + ".");
                }
            }, 1000);

            return () => clearInterval(interval);
        } else {
            setLoadingText("Loading");
        }
    }, [loadingText]);

    return (
        <div className="loading" style={{fontSize: size}}>
            {withBackground ? (
                <div className="loading-background">
                    <div className="loading-spinner"></div>
                    <div className="loading-text">{ loadingText }</div>
                </div>
            ) : (
                <>
                    <div className="loading-spinner"></div>
                    <div className="loading-text">{ loadingText }</div>
                </>
            )}
        </div>
    );
}

export default Loading;