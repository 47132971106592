// Tanner Fry
// tannerf1101@yahoo.com
// Common API calls.

import { getCorrectDomain } from '../AppSettings';
import { SubscriptionPlan } from '../App';
import { useNavigate } from 'react-router-dom';

export interface ReturnPropsAPIGetUserSubscriptionPlanResponse {
    subscriberPlan: SubscriptionPlan,
    errorMessage: string
}

export const APIGetUserSubscriptionPlanResponse = async (userEmail: string) => {
    let returnUserPlan: ReturnPropsAPIGetUserSubscriptionPlanResponse = {
        subscriberPlan: SubscriptionPlan.Free,
        errorMessage: ''
    }
    
    return fetch(getCorrectDomain() + '/api/users/is-subscriber', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            userId: JSON.parse(localStorage.getItem('user')).id,
            email: userEmail,
        })
    }).then(function (response) {
        if (response.ok) {                    
            return response.json();
        }
        return Promise.reject(response);
    }).then(async function (response) {                
        if (response[0].result === "Success") {
            returnUserPlan = {
                subscriberPlan: response[0].data.plan,
                errorMessage: ''
            };
            return returnUserPlan;
        } else {
            // TODO: Display message to user, error getting subscription plan.
            // console.log("Subscriber Result: ", JSON.stringify(response));
            // console.log("Subscriber email: ", userEmail);
            
            returnUserPlan = {
                subscriberPlan: SubscriptionPlan.Free,
                errorMessage: response[0].message
            };
            return returnUserPlan;
        }
    }).catch(function (error) {
        console.warn('Something went wrong with api subscriber check.', error);
        returnUserPlan = {
            subscriberPlan: SubscriptionPlan.Free,
            errorMessage: error
        };
        return returnUserPlan;
    });
};

// api/users/admin/add
export const APIGETServerNotification = () => {
    return fetch(getCorrectDomain() + '/api/notify', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(function (response) {        
        if (response.ok) {
            // console.log("APIGETServerNotification response: ", response);
            return response.json();
        }
        return Promise.reject(response);
    }).then(function (data) {
        // console.log("Fetched new server notification: " + JSON.stringify(data));
        return data[0];
    }).catch(function (error) {
        console.warn('Something went wrong.', error);
    });
}

// api/subscriptions/feature/auth
export const APIPOSTSubscriptionsFeatureAuth = (userId, feature: string, subFeature: string) => {
    return fetch(getCorrectDomain() + '/api/subscriptions/feature/auth', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            userId: userId,
            feature: feature,
            subFeature: subFeature
        })
    }).then(function (response) {        
        if (response.ok) {
            // console.log("APIGETSubscriptionsFeatureAuth response: ", response);
            return response.json();
        }
        return Promise.reject(response);
    }).then(function (data) {
        // console.log("Fetched new server feature auth: " + JSON.stringify(data));
        return data[0];
    }).catch(function (error) {
        console.warn('Something went wrong.', error);
    });
}

// A generic API call that adds the userId to the body of the request. This should be the new norm for all API calls
// as it will allow us to easily add the userId to the request body without having to do it manually every time.
// This will also cut down on the amount of code needed to make an API call. SEE APIMain.tsx for an example.
export async function fetchAPIGET<ResultType>(path: string): Promise<ResultType> {
    if (JSON.parse(localStorage.getItem('user')) === null) {
        return Promise.resolve(null);
    }

    // Check if token is valid
    await fetchAPIGETTokenIsValid();

    return fetch(getCorrectDomain() + path, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
        },
    }).then(function (response) {        
        if (response.ok) {                    
            return response.json();
        }
        return Promise.reject(response);
    }).then(async function (response) {
        return response[0] as ResultType;
    }).catch(function (error) {
        console.warn('Something went wrong with api fetch.', error);
        return Promise.reject(error);
    });
}
export async function fetchAPIPOSTByUserId<ResultType>(path: string, body: object): Promise<ResultType> {
    if (JSON.parse(localStorage.getItem('user')) === null) {
        console.log("User is not logged in.");
        return Promise.resolve(null);
    }

    // Check if token is valid
    await fetchAPIGETTokenIsValid();

    return fetch(getCorrectDomain() + path, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
        },        
        body: JSON.stringify({
            userId: JSON.parse(localStorage.getItem('user')).id,
            ...body
        })
    }).then(function (response) {  
        console.log("fetchAPIByUserId data with Bearer token from path: ", path);
              
        if (response.ok) {                    
            return response.json();
        }
        return Promise.reject(response);
    }).then(async function (response) {
        return response[0] as ResultType;
    }).catch(function (error) {
        console.warn('Something went wrong with api fetch.', error);
        return Promise.reject(error);
    });
}

export const fetchAPIGETTokenIsValid = async () => {
    // Check if token is valid
    let isValid = false;
    const tokenAuthResponse = await fetch(getCorrectDomain() + '/api/users/validate-token', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
        },
        body: JSON.stringify({
            userId: JSON.parse(localStorage.getItem('user')).id,
        })
    }).then(function (response) {
        if (response.ok) {
            isValid = true;
            return response.json();
        } else {
            console.log("User token is not valid.");
            return Promise.resolve(null);
        }
    }).catch(function (error) {
        console.warn('Something went wrong with api fetch token auth.', error);
        return Promise.reject(error);
    });
    console.log("Token auth response: ", tokenAuthResponse);
    
    
    if (!isValid) {
        // Logout user and remove info. Our LeftMenu component checks if user is logged in and if not, it will redirect to login page.
        localStorage.removeItem('user');
        window.location.reload();
        return Promise.resolve(null);
    }
}