// Tanner Fry
// tannerf1101@yahoo.com
// API calls for users.

import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { SubscriptionPlan } from "../App";
import { getCorrectDomain } from "../AppSettings";
import { setUser } from "../slices/UserSlice";
import { RootState } from "../AppStore";

export interface IUserFromUsersList {
    id: number,
    email: string,
    firstName: string,
    lastName: string,
}

export interface ReturnPropsAPIGetUsers {
    users: IUserFromUsersList[],
    isAPIDown: boolean
}

export const APIGetUsers = () => {
    return fetch(getCorrectDomain() + '/api/users', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // Token from local storage
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
        },
    }).then(function (response) {
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(response);
    }).then(function (data) {
        console.log("Fetched user list data: " + JSON.stringify(data[0]));
        const userData = [];
        userData.push(data[0]);
        const returnData: ReturnPropsAPIGetUsers = {
            users: data[0].map((user) => { return user; }),
            isAPIDown: false
        }
        return returnData;
    }).catch(function (error) {
        console.log("API user list fetch error: '" + JSON.stringify(error) + "'.");
        console.log("Is return equal to 'TypeError: Failed to fetch': " + (error === "TypeError: Failed to fetch"));
        if (error === "TypeError: Failed to fetch") {
            const returnData: ReturnPropsAPIGetUsers = {
                users: [],
                isAPIDown: true
            }
            return returnData;
        }
    });
}

export interface ReturnPropsAPIGetUserLoginResponse {
    newNavigationRoute: string,
    isSubscriber: boolean,
    subscriberPlan: SubscriptionPlan,
    error: string
}

export const APIGetUserLoginResponse = (userEmail: string, userPassword: string, dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    let returnLoginData: ReturnPropsAPIGetUserLoginResponse = {
        newNavigationRoute: '',
        isSubscriber: false,
        subscriberPlan: SubscriptionPlan.Free,
        error: ''
    }

    return fetch(getCorrectDomain() + '/api/users/login', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: userEmail,
            passHash: userPassword
        })
    }).then(function (response) {
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(response);
    }).then(async function (response) {
        // TODO: Login user and store info into cookies
        if (response[0].result === "Success") {
            console.log("General Result: ", JSON.stringify(response));
            let userData = response[0].data;
            console.log("User data from server: ", JSON.stringify(userData));
            
            // Store user info locally
            dispatch(setUser(userData));
            localStorage.setItem('user', JSON.stringify(userData));
            returnLoginData = {
                newNavigationRoute: '/dashboard',
                isSubscriber: false,
                subscriberPlan: SubscriptionPlan.Free,
                error: ''
            }

            console.log("User return data: ", returnLoginData);
            
            // TODO: Check if user has paid for a plan on Stripe. If so, set user's plan to paid
            // TODO: Make a request to our server which will then use the Stripe API to check if the user has a plan,
            //       essentially, via the steps below 
            // https://stripe.com/docs/api/charges/object?lang=node
            returnLoginData.isSubscriber = await fetch(getCorrectDomain() + '/api/users/is-subscriber', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: userEmail,
                })
            }).then(function (response) {
                if (response.ok) {                    
                    return response.json();
                }
                return Promise.reject(response);
            }).then(async function (response) {                
                if (response[0].result === "Success") {
                    returnLoginData.subscriberPlan = response[0].data.plan;
                    return true;
                } else {
                    // TODO: Display message to user, error logging in.
                    console.log("Subscriber Result: ", JSON.stringify(response));
                    // Return new navigation direction: none since we want to stay on the page
                    return false;
                }
            }).catch(function (error) {
                console.warn('Something went wrong with api subscriber check.', error);
                return false;
            });

            // Return data
            return returnLoginData;
        } else {
            // TODO: Display message to user, error logging in.
            console.log("Error Results: ", JSON.stringify(response));
            returnLoginData = {
                newNavigationRoute: '',
                isSubscriber: false,
                subscriberPlan: SubscriptionPlan.Free,
                error: response[0].message
            }
            // Return new navigation direction: none since we want to stay on the page
            return returnLoginData;
        }
    }).catch(function (error) {
        console.warn('Something went wrong with api login request.', error);
        return returnLoginData;
    });
}