// Tanner Fry
// tannerf1101@yahoo.com
// The redux slice for the maintenance dashboard.

import { createSlice } from '@reduxjs/toolkit'

export interface IMainHobbiesSlice {
	mainHobbiesSlice: {
		hobbiesHuntingContentHeight: number;
	}
}

export const MainHobbiesSlice = createSlice({
	name: 'mainHobbiesSlice',
	initialState: {
		hobbiesHuntingContentHeight: 0,
	},
	reducers: {
		setHobbiesHuntingContentHeight: (state, action) => {
			state.hobbiesHuntingContentHeight = action.payload;
		},
	},
})

// Action creators are generated for each case reducer function
export const { setHobbiesHuntingContentHeight } = MainHobbiesSlice.actions

export default MainHobbiesSlice.reducer