import './css/dashboards/Maintenance.css';
import './css/dashboards/Settings.css';
import './css/dashboards/News.css';
import './css/dashboards/UserDashboard.css';
import './css/dashboards/Pricing.css';
import './css/general/App.css';
import './css/general/Buttons.css';
import './css/general/Content.css';
import './css/general/Forms.css';
import './css/general/Menu.css';
import './css/general/Modal.css';
import './css/general/Utilities.css';
import './css/components/ContentBills.css';
import './css/general/Extra.css';
import './css/Inputs.css';
import './css/Leaflet.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Suspense, lazy, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/elements/Header';
import RegisterForm from './components/forms/RegisterForm';
import LoginForm from './components/forms/LoginForm';
import { IUserProps } from './Dashboard';
import PrivateRoute from './components/privateRoute';
import RouteNotFound from './RouteNotFound';
import { APIGetUserSubscriptionPlanResponse, ReturnPropsAPIGetUserSubscriptionPlanResponse } from './api/Common';
import useScript from './components/Common';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './AppHooks';
import { setSubscriptionStatus } from './slices/UserSlice';
import { Button } from './components/elements/Button';
import Loading from './components/elements/Loading';
import HobbiesHuntingSeasons from './components/elements/dashboardElements/HobbiesHuntingSeasons';
const Home = lazy(() => import('./Home'));
const About = lazy(() => import('./About'));
const Dashboard = lazy(() => import('./Dashboard'));
const GeneralDashboard = lazy(() => import('./GeneralDashboard'));
const Pricing = lazy(() => import('./Pricing'));
const CarMaintenance = lazy(() => import('./CarMaintenance'));
const Education = lazy(() => import('./Education'));
const EducationFlashCards = lazy(() => import('./components/elements/dashboardElements/EducationFlashCards'));
const EducationGuides = lazy(() => import('./components/elements/dashboardElements/EducationGuides'));
const Gardening = lazy(() => import('./Gardening'));
const Explore = lazy(() => import('./Explore'));
const Weather = lazy(() => import('./Weather'));
const Settings = lazy(() => import('./Settings'));
const SettingsAccountInformation = lazy(() => import('./components/elements/dashboardElements/SettingsAccountInformation'));
const SettingsPreferences = lazy(() => import('./components/elements/dashboardElements/SettingsPreferences'));
const SettingsNotifications = lazy(() => import('./components/elements/dashboardElements/SettingsNotifications'));
const SettingsSecurity = lazy(() => import('./components/elements/dashboardElements/SettingsSecurity'));
const SettingsPrivacy = lazy(() => import('./components/elements/dashboardElements/SettingsPrivacy'));
const SettingsHelp = lazy(() => import('./components/elements/dashboardElements/SettingsHelp'));
const SettingsFeedback = lazy(() => import('./components/elements/dashboardElements/SettingsFeedback'));
const News = lazy(() => import('./News'));
const Maintenance = lazy(() => import('./Maintenance'));
const QuestionsAndAnswers = lazy(() => import('./QuestionsAndAnswers'));
const Animals = lazy(() => import('./Animals'));
const Notes = lazy(() => import('./Notes'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const Cooking = lazy(() => import('./Cooking'));
const RecipeDetails = lazy(() => import('./components/elements/dashboardElements/RecipeDetails'));
const PasswordPreResetForm = lazy(() => import('./components/forms/PasswordPreResetForm'));
const PasswordResetOTPForm = lazy(() => import('./components/forms/PasswordResetOTPForm'));
const PasswordResetForm = lazy(() => import('./components/forms/PasswordResetForm'));
const Modal = lazy(() => import('./components/elements/Modal'));
const FeedbackForm = lazy(() => import('./components/forms/FeedbackForm'));
const GenericOkayForm = lazy(() => import('./components/forms/GenericOkayForm'));
const Hobbies = lazy(() => import('./Hobbies'));
const HobbiesHunting = lazy(() => import('./components/elements/dashboardElements/HobbiesHunting'));
const HobbiesFishing = lazy(() => import('./components/elements/dashboardElements/HobbiesFishing'));
const HobbiesProjects = lazy(() => import('./components/elements/dashboardElements/HobbiesProjects'));
const ActionPanel = lazy(() => import('./components/utilities/ActionPanel'));

// https://react-icons.github.io/react-icons

function App() {
	const userPlan = useAppSelector(state => state.users.subscriptionPlan);
	const dispatch = useAppDispatch();
	const [isFeedbackFormOpen, setFeedbackFormOpen] = useState(false);
	const [isFeedbackSubmitFormOpen, setFeedbackSubmitFormOpen] = useState(false);
	const isLoggedIn = useAppSelector(state => state.users.isLoggedIn);
	const hobbiesHuntingContentHeight = useAppSelector(state => state.hobbies.hobbiesHuntingContentHeight);
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	useScript('https://unpkg.com/leaflet@1.9.4/dist/leaflet.js');  // we store our css with other css files and load js here
	useScript('https://kit.fontawesome.com/15f18dcaeb.js');

	useEffect(() => {
		// Scroll certain amounts from current position 
		window.scrollBy({ 
			top: 10, // could be negative value
			left: 0, 
			behavior: 'smooth' 
		});
	}, [])

	useEffect(() => {
        // console.log("User plan: ", userPlan);

    }, [userPlan])

	useEffect(() => {
		const fetchUserPlan = async () => {
			// Get user email
			let currentUser: IUserProps = JSON.parse(localStorage.getItem('user'));
			// console.log("User localstorage data: ", currentUser);

			// Check for subscription plan
			if (currentUser != null) {
				let subscriptionResponse: ReturnPropsAPIGetUserSubscriptionPlanResponse = await APIGetUserSubscriptionPlanResponse(currentUser.email);

				// Set user plan from api if api supplies one, else assume free plan
				dispatch(setSubscriptionStatus(subscriptionResponse.subscriberPlan ? subscriptionResponse.subscriberPlan : SubscriptionPlan.Free));
			}
		}
		fetchUserPlan();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Periodically check if user has a subscription plan.
	useEffect(() => {
		const interval = setInterval(async () => {
			// Get user email
			let currentUser: IUserProps = JSON.parse(localStorage.getItem('user'));
			console.log("User localstorage data: ", currentUser);
			

			// Check for subscription plan
			if (currentUser != null) {
				let subscriptionResponse: ReturnPropsAPIGetUserSubscriptionPlanResponse = await APIGetUserSubscriptionPlanResponse(currentUser.email);

				// Set user plan from api if api supplies one, else assume free plan
				dispatch(setSubscriptionStatus(subscriptionResponse.subscriberPlan ? subscriptionResponse.subscriberPlan : SubscriptionPlan.Free));
				console.log("User plan: ", subscriptionResponse.subscriberPlan ? subscriptionResponse.subscriberPlan : SubscriptionPlan.Free);
				
			}
		}, 600000);  // 10 minutes * 60 seconds * 1000 milliseconds = 600000 milliseconds
		return () => {
			clearInterval(interval);
		};
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	return (
		<div 
			className='App' 
			// onContextMenu={(e) => {
            // 	e.preventDefault(); // prevent the default behaviour when right clicked
            // 	console.log("Right Click");
			// 	// TODO: Implement right click menu with redux options and more.	
          	// }}
		  >
			<Suspense fallback={<Loading />}>
				<Header />
				<div className='app-content'>
					<ActionPanel />
					<Routes>
						<Route path='/' element={
							<PrivateRoute>
								<Home />
							</PrivateRoute>
						}  />
						<Route path='/about' element={ <About /> } />
						<Route path='/animals' element={<PrivateRoute><Animals /></PrivateRoute>} />
						<Route path='/cooking' element={<PrivateRoute><Cooking /></PrivateRoute>} />
						<Route path='/cooking/recipe/:recipeId' element={<PrivateRoute><RecipeDetails /></PrivateRoute>} />
						<Route path='/dashboard' element={<PrivateRoute><Dashboard userPlan={userPlan}/></PrivateRoute>}>
							<Route path='' element={<GeneralDashboard />}/>
						</Route>
						<Route path='/explore' element={<PrivateRoute><Explore /></PrivateRoute>} />
						<Route path='/gardening' element={<PrivateRoute><Gardening /></PrivateRoute>} >
							<Route path=':month' element={<PrivateRoute><Gardening /></PrivateRoute>} />
							<Route path='/gardening/vegetables/:vegetable' element={<PrivateRoute><Gardening /></PrivateRoute>} />
							<Route path='/gardening/fruits/:fruit' element={<PrivateRoute><Gardening /></PrivateRoute>} />
							<Route path='/gardening/herbs/:herb' element={<PrivateRoute><Gardening /></PrivateRoute>} />
						</Route>
						<Route path='/hobbies' element={ <PrivateRoute><Hobbies /></PrivateRoute> } >
							<Route index element={ <HobbiesFishing /> } />
							<Route path='fishing' element={ <HobbiesFishing /> } />
							{/* <Route path='health' element={ <HobbiesHealth /> } /> */}
							<Route path='hunting' element={ <HobbiesHunting /> } >
								<Route path='seasons' element={ <HobbiesHuntingSeasons contentHeight={hobbiesHuntingContentHeight}/> } />
							</Route>
							{/* <Route path='painting' element={ <HobbiesPainting /> } /> */}
							{/* <Route path='woodworking' element={ <HobbiesWoodworking /> } /> */}
							{/* Special */}
							<Route path='projects' element={ <HobbiesProjects /> } />
						</Route>
						<Route path='/login' element={ <LoginForm userPlan={userPlan} /> } />
						<Route path='/privacy-policy' element={ <PrivacyPolicy /> } />
						<Route path='/pricing' element={ <Pricing /> } />
						<Route path='/register' element={ <RegisterForm /> } />
						<Route path='/pre-password-reset' element={ <PasswordPreResetForm /> } />
						<Route path='/password-reset-otp' element={ <PasswordResetOTPForm /> } />
						<Route path='/password-reset' element={ <PasswordResetForm /> } />
						<Route path='/education' element={ <PrivateRoute><Education /></PrivateRoute> } />
						<Route path='/education/flash-cards' element={ <PrivateRoute><EducationFlashCards /></PrivateRoute> } />
						<Route path='/education/guides' element={ <PrivateRoute><EducationGuides /></PrivateRoute> } />
						<Route path='/car-maintenance' element={ <PrivateRoute><CarMaintenance /></PrivateRoute> } />
						<Route path='/maintenance' element={ <PrivateRoute><Maintenance /></PrivateRoute> } />
						<Route path='/notes' element={<PrivateRoute><Notes /></PrivateRoute>} />
						{/* <Route path='/news' element={<PrivateRoute><News /></PrivateRoute>} /> */}
						<Route path='/q&a' element={ <QuestionsAndAnswers /> } />
						<Route path='/settings' element={ <Settings /> } >
							<Route index element={ <SettingsAccountInformation /> } />
							<Route path='account-information' element={ <SettingsAccountInformation /> } />
							<Route path='preferences' element={ <SettingsPreferences /> } />
							<Route path='notifications' element={ <SettingsNotifications /> } />
							<Route path='security' element={ <SettingsSecurity /> } />
							<Route path='privacy' element={ <SettingsPrivacy /> } />
							<Route path='help' element={ <SettingsHelp /> } />
							<Route path='feedback' element={ <SettingsFeedback /> } />
						</Route>
						{/* Look closely below. The dashboards are children of the user dashboard. */}
						<Route path='/weather' element={<PrivateRoute><Weather /></PrivateRoute>} />
						<Route path='*' element={ <RouteNotFound /> } />
					</Routes>
				</div>
				<footer className='app-footer'>
					{isLoggedIn && (
						<div className='feedback-container'>
							<Button text='Give Feedback' onClick={() => {setFeedbackFormOpen(!isFeedbackFormOpen)}} />
						</div>
					)}
					<Link to='/about'>About</Link>
					<Link to='/privacy-policy'>Privacy Policy</Link>
					<span>© 2023 DigiMastered Works LLC</span>
				</footer>
				{isFeedbackSubmitFormOpen && (
					<GenericOkayForm title="Submitted!" description="Your feedback has been submitted." isShowingGenericOkayForm={setFeedbackSubmitFormOpen}/>
				)}
				{isFeedbackFormOpen && (
					<>
						<Modal
							title='Feedback'
							textInfo='Please provide feedback below.' 
							classes='feedback-modal'
							form={<FeedbackForm isFeedbackSubmitModal={setFeedbackSubmitFormOpen} isModelShowing={setFeedbackFormOpen} />}
						/>
					</>
				)}
			</Suspense>
		</div>
	);
}

export enum SubscriptionPlan {
	Free = 'Free',
	Base = 'Base',
	Packed = 'Packed',
}

export default App;
