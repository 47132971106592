// Tanner Fry
// tannerf1101@yahoo.com
// Data on all hunting regulation and license information for the hobbies dashboard.

export interface IHuntingRegulationsSourcesProps {
    state: string,
    stateName: string,
    regulationSource: string,
    licenseSource: string,
}

export interface IHuntingGuideHuntProps {

}

export const CONST_HOBBIES_HUNTING_REGULATIONS_SOURCES_DATA: IHuntingRegulationsSourcesProps[] = [
    {
        state: "AL",
        stateName: "Alabama",
        regulationSource: "https://www.outdooralabama.com/hunting",
        licenseSource: "https://www.outdooralabama.com/license-information"
    },
    {
        state: "AK",
        stateName: "Alaska",
        regulationSource: "https://www.adfg.alaska.gov/index.cfm?adfg=hunting.main",
        licenseSource: "https://www.adfg.alaska.gov/index.cfm?adfg=license.main"
    },
    {
        state: "AZ",
        stateName: "Arizona",
        regulationSource: "https://www.azgfd.com/hunting/regulations/",
        licenseSource: "https://license.azgfd.com/"
    },
    {
        state: "AR",
        stateName: "Arkansas",
        regulationSource: "https://www.agfc.com/regulations/general-hunting-regulations/",
        licenseSource: "https://ar-licensing.s3licensing.com/?catalogItem=Hunting"
    },
    {
        state: "CA",
        stateName: "California",
        regulationSource: "https://wildlife.ca.gov/Regulations/hunting",
        licenseSource: "https://wildlife.ca.gov/Licensing/Hunting"
    },
    {
        state: "CO",
        stateName: "Colorado",
        regulationSource: "https://cpw.state.co.us/placestogo/Pages/Hunting.aspx",
        licenseSource: "https://cpw.state.co.us/buyapply/pages/hunting.aspx"
    },
    {
        state: "CT",
        stateName: "Connecticut",
        regulationSource: "https://portal.ct.gov/DEEP/Hunting/Hunting-and-Trapping-Information",
        licenseSource: "https://portal.ct.gov/DEEP/Hunting/Hunting-Licenses-and-Permits"
    },
    {
        state: "DE",
        stateName: "Delaware",
        regulationSource: "https://dnrec.delaware.gov/fish-wildlife/hunting/",
        licenseSource: "https://dnrec.delaware.gov/fish-wildlife/licenses/",
    },
    {
        state: "DC",
        stateName: "District of Columbia",
        regulationSource: "Illegal to hunt in DC",
        licenseSource: "Illegal to hunt in DC"
    },
    {
        state: "FL",
        stateName: "Florida",
        regulationSource: "https://myfwc.com/hunting/",
        licenseSource: "https://myfwc.com/license/"
    },
    {
        state: "GA",
        stateName: "Georgia",
        regulationSource: "https://georgiawildlife.com/hunting/hunter-resources",
        licenseSource: "https://georgiawildlife.com/licenses-permits-passes"
    },
    {
        state: "HI",
        stateName: "Hawaii",
        regulationSource: "https://dlnr.hawaii.gov/recreation/hunting/",
        licenseSource: "https://hunting.ehawaii.gov/hunting/license.html"
    },
    {
        state: "ID",
        stateName: "Idaho",
        regulationSource: "https://idfg.idaho.gov/rules/big-game",
        licenseSource: "https://idfg.idaho.gov/licenses"
    },,
    {
        state: "IL",
        stateName: "Illinois",
        regulationSource: "https://dnr.illinois.gov/hunting/factsheets.html",
        licenseSource: "https://dnr.illinois.gov/lpr/huntinglicensesfees.html"
    },
    {
        state: "IN",
        stateName: "Indiana",
        regulationSource: "https://www.in.gov/dnr/fish-and-wildlife/hunting-and-trapping/",
        licenseSource: "https://www.in.gov/dnr/fish-and-wildlife/indiana-fish-and-wildlife-online-license-system/"
    },
    {
        state: "IA",
        stateName: "Iowa",
        regulationSource: "https://www.iowadnr.gov/Hunting/Hunting-Licenses-Laws",
        licenseSource: "https://www.iowadnr.gov/Hunting/Hunting-Licenses-Laws"
    },
    {
        state: "KS",
        stateName: "Kansas",
        regulationSource: "https://ksoutdoors.com/Hunting/Hunting-Regulations",
        licenseSource: "https://ksoutdoors.com/Hunting/Applications-and-Fees"
    },
    {
        state: "KY",
        stateName: "Kentucky",
        regulationSource: "https://apps.legislature.ky.gov/law/kar/titles/301/",
        licenseSource: "https://app.fw.ky.gov/Solar/"
    },
    {
        state: "LA",
        stateName: "Louisiana",
        regulationSource: "https://www.wlf.louisiana.gov/page/seasons-and-regulations",
        licenseSource: "https://www.wlf.louisiana.gov/page/hunting-licenses-permits-tags"
    },
    {
        state: "ME",
        stateName: "Maine",
        regulationSource: "https://www.maine.gov/ifw/hunting-trapping/hunting/index.html",
        licenseSource: "https://www.maine.gov/ifw/hunting-trapping/licenses-permits/index.html"
    },
    {
        state: "MD",
        stateName: "Maryland",
        regulationSource: "https://dnr.maryland.gov/wildlife/pages/hunt_trap/home.aspx",
        licenseSource: "https://dnr.maryland.gov/wildlife/Pages/Licenses/home.aspx"
    },
    {
        state: "MA",
        stateName: "Massachusetts",
        regulationSource: "https://www.mass.gov/hunting-regulations",
        licenseSource: "https://www.mass.gov/buy-fishing-or-hunting-licenses"
    },
    {
        state: "MI",
        stateName: "Michigan",
        regulationSource: "https://www.michigan.gov/dnr/managing-resources/laws/regulations",
        licenseSource: "https://www.mdnr-elicense.com/"
    },
    {
        state: "MN",
        stateName: "Minnesota",
        regulationSource: "https://www.dnr.state.mn.us/regulations/hunting/index.html",
        licenseSource: "https://www.dnr.state.mn.us/licenses/hunting/index.html"
    },
    {
        state: "MS",
        stateName: "Mississippi",
        regulationSource: "https://www.mdwfp.com/law-enforcement/hunting-rules-regs/",
        licenseSource: "https://www.mdwfp.com/license/hunting/"
    },
    {
        state: "MO",
        stateName: "Missouri",
        regulationSource: "https://www.mdwfp.com/license/hunting/",
        licenseSource: "https://mdc.mo.gov/permits/hunting-permits"
    },
    {
        state: "MT",
        stateName: "Montana",
        regulationSource: "https://fwp.mt.gov/hunt/regulations",
        licenseSource: "https://fwp.mt.gov/myfwp"
    },
    {
        state: "NE",
        stateName: "Nebraska",
        regulationSource: "https://outdoornebraska.gov/guides-maps/hunting-guides/",
        licenseSource: "https://outdoornebraska.gov/permits/hunting-permits/"
    },
    {
        state: "NV",
        stateName: "Nevada",
        regulationSource: "https://www.ndow.org/rules-regulations/",
        licenseSource: "https://www.ndow.org/apply-buy/apply-buy-hunting/"
    },
    {
        state: "NH",
        stateName: "New Hampshire",
        regulationSource: "https://www.wildlife.nh.gov/hunting-nh/hunter-education",
        licenseSource: "https://www.wildlife.nh.gov/licenses-and-permits"
    },
    {
        state: "NJ",
        stateName: "New Jersey",
        regulationSource: "https://dep.nj.gov/njfw/hunting/seasons-and-regulations/",
        licenseSource: "https://dep.nj.gov/njfw/hunting/buy-a-license/"
    },
    {
        state: "NM",
        stateName: "New Mexico",
        regulationSource: "https://www.wildlife.state.nm.us/hunting/general-rules/",
        licenseSource: "https://www.wildlife.state.nm.us/hunting/licenses-and-permits/"
    },
    {
        state: "NY",
        stateName: "New York",
        regulationSource: "https://www.dec.ny.gov/things-to-do/hunting/hunting-trapping-regulations",
        licenseSource: "https://www.dec.ny.gov/regulatory/permits-licenses/sporting-and-use/sporting/hunting-license"
    },
    {
        state: "NC",
        stateName: "North Carolina",
        regulationSource: "https://www.ncwildlife.org/Hunting/Hunting-in-North-Carolina",
        licenseSource: "https://www.ncwildlife.org/Licensing/Hunting-Fishing-Trapping-Licenses"
    },
    {
        state: "ND",
        stateName: "North Dakota",
        regulationSource: "https://gf.nd.gov/hunting/regulations",
        licenseSource: "https://gf.nd.gov/hunting/license-general-info"
    },
    {
        state: "OH",
        stateName: "Ohio",
        regulationSource: "https://ohiodnr.gov/buy-and-apply/hunting-fishing-boating/hunting-resources",
        licenseSource: "https://ohiodnr.gov/buy-and-apply/hunting-fishing-boating/hunting-resources/hunting-licenses-permits"
    },
    {
        state: "OK",
        stateName: "Oklahoma",
        regulationSource: "https://www.wildlifedepartment.com/hunting/regs",
        licenseSource: "https://gooutdoorsoklahoma.com/"
    },
    {
        state: "OR",
        stateName: "Oregon",
        regulationSource: "https://myodfw.com/articles/oregon-fishing-hunting-regulations-and-updates",
        licenseSource: "https://odfw.huntfishoregon.com/login"
    },
    {
        state: "PA",
        stateName: "Pennsylvania",
        regulationSource: "https://www.pgc.pa.gov/HuntTrap/Law/pages/seasonsandbaglimits.aspx",
        licenseSource: "https://www.pgc.pa.gov/HuntTrap/LicensesandPermits/Pages/default.aspx"
    },
    {
        state: "RI",
        stateName: "Rhode Island",
        regulationSource: "https://dem.ri.gov/natural-resources-bureau/fish-wildlife/wildlife-hunting",
        licenseSource: "https://dem.ri.gov/natural-resources-bureau/fish-wildlife/licenses-applications"
    },
    {
        state: "SC",
        stateName: "South Carolina",
        regulationSource: "https://www.dnr.sc.gov/hunting.html",
        licenseSource: "https://www.dnr.sc.gov/licensing.html"
    },
    {
        state: "SD",
        stateName: "South Dakota",
        regulationSource: "https://gfp.sd.gov/pages/regulations/",
        licenseSource: "https://license.gooutdoorssouthdakota.com/Licensing/CustomerLookup.aspx",
    },
    {
        state: "TN",
        stateName: "Tennessee",
        regulationSource: "https://www.tn.gov/twra/guide/hunting-regulations.html",
        licenseSource: "https://www.tn.gov/twra/license-sales.html#general"
    },
    {
        state: "TX",
        stateName: "Texas",
        regulationSource: "https://tpwd.texas.gov/regulations/outdoor-annual/hunting/",
        licenseSource: "https://tpwd.texas.gov/regulations/outdoor-annual/licenses/"
    },
    {
        state: "UT",
        stateName: "Utah",
        regulationSource: "https://wildlife.utah.gov/hunting/hunting-regulation.html",
        licenseSource: "https://wildlife.utah.gov/licenses/licenses-permits.html"
    },
    {
        state: "VT",
        stateName: "Vermont",
        regulationSource: "https://vtfishandwildlife.com/hunt/hunting-regulations",
        licenseSource: "https://vtfishandwildlife.com/licenses-and-lotteries/license-center"
    },
    {
        state: "VA",
        stateName: "Virginia",
        regulationSource: "https://dwr.virginia.gov/hunting/regulations/",
        licenseSource: "https://dwr.virginia.gov/licenses/"
    },
    {
        state: "WA",
        stateName: "Washington",
        regulationSource: "https://wdfw.wa.gov/hunting/regulations",
        licenseSource: "https://wdfw.wa.gov/licenses"
    },
    {
        state: "WV",
        stateName: "West Virginia",
        regulationSource: "https://wvdnr.gov/hunting/hunting-regulations/",
        licenseSource: "https://www.wvhunt.com/login"
    },
    {
        state: "WI",
        stateName: "Wisconsin",
        regulationSource: "https://dnr.wisconsin.gov/topic/hunt/regulations",
        licenseSource: "https://gowild.wi.gov/"
    },
    {
        state: "WY",
        stateName: "Wyoming",
        regulationSource: "https://wgfd.wyo.gov/regulations",
        licenseSource: "https://wgfapps.wyo.gov/LoginPortal/Login1.aspx"
    }
]

export const CONST_HOBBIES_HUNTING_COMMON_HUNT_GUIDE_DATA: IHuntingGuideHuntProps[] = [
    
]