// Tanner Fry
// tannerf1101@yahoo.com
// API calls for user preferences.

import { getCorrectDomain } from "../AppSettings";

export interface IUserAnalytics {
    loginCount: number,
    urlChangeCount: number,
    lastLogin: string,
}

export const APIGetUserAnalytics = () => {
    return fetch(getCorrectDomain() + '/api/useranalytics', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
        },
        body: JSON.stringify({
            userId: JSON.parse(localStorage.getItem('user')).id,
        })
    }).then(function (response) {
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(response);
    }).then(function (data) {
        // console.log("Fetched user analytics data: ", data[0][0]);
        return data[0];
    }).catch(function (error) {
        console.log("API user analytics fetch error: '" + JSON.stringify(error) + "'.");
        console.log("Is return equal to 'TypeError: Failed to fetch': " + (error === "TypeError: Failed to fetch"));
        if (error === "TypeError: Failed to fetch") {
            console.warn('Something went wrong.', error);
        }
    });
}

export const APIGetUserAnalyticsByUserId = (signal) => {
    return fetch(getCorrectDomain() + '/api/useranalytics', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
        },
        body: JSON.stringify({
            userId: JSON.parse(localStorage.getItem('user')).id,
        }), 
        signal: signal
    }).then(function (response) {        
        if (response.ok) {
            console.log("APIGetUserAnalyticsByUserId response: ", response);
            return response.json();
        }
        return Promise.reject(response);
    }).then(function (data) {
        // console.log("Fetched notes data: " + JSON.stringify(data));
        return data[0];
    }).catch(function (error) {
        console.warn('Something went wrong.', error);
    });
}

export const APIPOSTUserAnalytics = () => {

    return fetch(getCorrectDomain() + '/api/userpreferences/add', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
        },
        body: JSON.stringify({
            userId: JSON.parse(localStorage.getItem('user')).id,
        })
    }).then(function (response) {
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(response);
    }).then(function (data) {
        console.log(JSON.stringify(data));
        return data[0];
    }).catch(function (error) {
        console.warn('Something went wrong.', error);
    });
}

export const APIPOSTUPDATEUserAnalyticsLoginTrack = () => {

    return fetch(getCorrectDomain() + '/api/useranalytics/update/login', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
        },
        body: JSON.stringify({
            userId: JSON.parse(localStorage.getItem('user')).id,
        })
    }).then(function (response) {
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(response);
    }).then(function (data) {
        console.log(JSON.stringify(data));
    }).catch(function (error) {
        console.warn('Something went wrong.', error);
    });
}