// Tanner Fry
// tannerf1101@yahoo.com
// Common functions used throughout the app.

import { useEffect } from 'react';
import { SubscriptionPlan } from '../App';

const useScript = url => {
	useEffect(() => {
		const script = document.createElement('script');
		
		script.src = url;
		script.async = true;
		
		document.body.appendChild(script);
		
		return () => {
			document.body.removeChild(script);
		}
	}, [url]);
};

export const TruncateString = (str, num) => {
	// If the length of str is less than or equal to num
	// just return str--don't truncate it.
	if (str.length <= num) {
		return str
	}

	// Return str truncated with '...' concatenated to the end of str.
	return str.slice(0, num) + '...'
}

export const CommaSeparateList = (list) => {
	let listString = '';

	let length = list.length;
	let i = 0;
	list.forEach((item) => {
		listString += item;
		if (i < length - 1) {
			listString += ', ';
		}
		i++;
	});

	return listString;
}

export const TruncateDecimals = (number, digits) => {
	var multiplier = Math.pow(10, digits),
	adjustedNum = number * multiplier,
	truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);
	
	return truncatedNum / multiplier;
};

export const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
	const { top, left, bottom, right } = el.getBoundingClientRect();
	const { innerHeight, innerWidth } = window;

	return partiallyVisible ? (
		(top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight))
		&& ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth)
	) : (
		top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth
	);
};

export const checkSizeIsMobile = (size) => {
	if (size < 768 && size > 0) {
		return true;
	} else {
		return false;
	}
};

export const isOverflownHeight = (element) => {
	return element.scrollHeight > element.clientHeight;
}

export const isOverflownWidth = (element) => {
	return element.scrollWidth > element.clientWidth;
}

export const componentToHex = (c) => {
	var hex = c.toString(16);
	return hex.length == 1 ? "0" + hex : hex;
}
  
export const rgbToHex = (r, g, b) => {
	return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const hexToRgb = (hex) => {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
	r: parseInt(result[1], 16),
	g: parseInt(result[2], 16),
	b: parseInt(result[3], 16)
	} : null;
}

// https://stackoverflow.com/questions/3115150/how-to-escape-regular-expression-special-characters-using-javascript
export const escapeRegExpSpecialCharacters = (text: string) => {
	if (typeof text === 'string' && text !== null && text !== undefined) {
		return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
	} else {
		return text;
	}
}

export const escapeRegExpSpecialCharactersDoubleQuotesSingleQuotes = (text: string) => {
	console.log("Text: ", text);
	console.log("Text type: ", typeof text);
	console.log("Text null: ", text === null);
	console.log("Text undefined: ", text === undefined);
	
	
	
	
	if (typeof text === 'string' && text !== null && text !== undefined) {
		console.log("Replacing text: ", text);
		let newText = text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
		console.log("New text: ", newText);
		
		
		return text.replace(/[-[\]{}()*+?.,\\^$|#\s"']/g, '\\$&');
	} else {
		return text;
	}
}

export const replaceEscapedQuotes = (text: string): string => {
	let newText = text;
	if (typeof text === 'string' && text !== null && text !== undefined) {
		newText = text.replace(/&quot;/g, '"');
		newText = newText.replace(/&#039;/g, "'");
		newText = newText.replace(/&#x27;/g, "'");
	}
	return newText;
}

// Return a string of the plan features
export const returnPlanFeatures = (plan: SubscriptionPlan): string => {
	let string = '';
	if (plan === SubscriptionPlan.Free) {
		string = '5 Animals, Task Tracking, Gardening Wiki, Maintenance Tracking, Weather Data, and Work Time Tracking'
	} else if (plan === SubscriptionPlan.Base) {
		string = '25 Animals, Bill Tracking, Cooking/Recipes/Groceries, Gardening Wiki, '
					 + 'Hobby Tracking (Fishing/Hunting), Maintenance Tracking, Notes, '
					 + 'Task Tracking, Weather Data, Weather Radar, Work Time Tracking';
	} else if (plan === SubscriptionPlan.Packed) {
		string = 'Unlimited Animals, Bill Tracking, Cooking/Recipes/Groceries, Encrypted Notes, '
					 + 'Garden Layout Builder, Gardening Wiki, Hobby Tracking (Fishing/Hunting), '
					 + ' Maintenance Tracking, Notes, Task Tracking, Weather Data, Weather Radar, Work Time Tracking';
	}

	return string;
}
  

export default useScript;