// Tanner Fry
// tannerf1101@yahoo.com
// App settings that are global.

// http://3.98.93.165:8080
// Localhost dev domain: https://www.npmjs.com/package/local-cors-proxy

export const _settings = {
    // API HOSTNAME should be https://myhomelife.app when deploying
    API_HOSTNAME: 'https://myhomelife.app',
    API_DEV_HOSTNAME: 'http://localhost:7778',  // Use for knowing what host the API is on and pinging it correctly
    API_SSE_NOTIFICTION_URL: '/api/sse/general-notification',
    API_COMPUTER_PORT: '7778',
    API_CONTAINER_PORT: '7778',
    APP_COMPUTER_PORT: '7777',
    APP_CONTAINER_PORT: '3000',  // React default port
    ENVIRONMENT: 'prod',  // Can be dev or prod
    ENABLE_MOBILE: false,  // Used for mobile development
    MYSQL_COMPUTER_PORT: '3308',
    MYSQL_CONTAINER_PORT: '3306',  // MySQL default port
    IS_CONSTANT_LOADING_ENABLED: false,  // Used for quick refreshes on app.
};

// https://stripe.com/docs/keys#obtain-api-keys - Stripe API keys
export const _settings_customer = {
    TEST_BILLING_URL: 'https://billing.stripe.com/p/login/test_8wM4iP1G91jU9Fu144',
    LIVE_BILLING_URL: 'https://billing.stripe.com/p/login/9AQ7ti7RBdVX6KA4gg',
    NOTICE_BANNERS: [
        {
            location: '/',
            title: 'Welcome to MyHomeLife!',
        },
        {
            location: '/dashboard',
            title: '',
        },
        {
            location: '/animals',
            title: '',
        },
        {
            location: '/cooking',
            title: '',
        },
        {
            location: '/education',
            title: 'This page is still under development!',
        },
        {
            location: '/gardening',
            title: '',
        },
        {
            location: '/hobbies/fishing',
            title: 'This page is still under development!',
        },
        {
            location: '/hobbies/hunting',
            title: 'This page is still under development!',
        },
        {
            location: '/hobbies/projects',
            title: 'This page is still under development!',
        },
        {
            location: '/hobbies',
            title: 'This page is still under development!',
        },
        {
            location: '/maintenance',
            title: 'This page is still under development!',
        },
        {
            location: '/news',
            title: '',
        },
        {
            location: '/notes',
            title: 'Encrypted notes are coming soon!',
        },
        {
            location: '/weather',
            title: '',
        }
    ],
};

export const _settings_weather = {
    units: 'imperial', // imperial or metric
};

// A list of non private routes that can be exported
export const _settings_non_private_routes = [
	'/',
	'/about',
	'/pricing',
	'/login',
	'/register',
];


export const getCorrectDomain = () => {
    if (_settings.ENVIRONMENT === 'dev') {
        return _settings.API_DEV_HOSTNAME;
    } else if (_settings.ENVIRONMENT.includes('192.168.0.')) {
        return _settings.ENVIRONMENT;  
    } else {
        return _settings.API_HOSTNAME;
    }
}

export default _settings;