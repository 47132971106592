// Tanner Fry
// tannerf1101@yahoo.com
// The redux slice for the maintenance dashboard.

import { createSlice } from '@reduxjs/toolkit'
import { IMaintenanceObjectiveItemsProps } from '../Maintenance'

export interface IMainUserSlice {
	mainMaintenanceSlice: {
		selectedMaintenanceCommonItem: IMaintenanceObjectiveItemsProps | null;
		selectedMaintenanceObjectiveItem: IMaintenanceObjectiveItemsProps | null;
	}
}

export const MainMaintenanceSlice = createSlice({
	name: 'mainMaintenanceSlice',
	initialState: {
		selectedMaintenanceCommonItem: null,
		selectedMaintenanceObjectiveItem: null,
	},
	reducers: {
		setSelectedMaintenanceCommonItem: (state, action) => {
			state.selectedMaintenanceCommonItem = action.payload;
		},
		setSelectedMaintenanceObjectiveItem: (state, action) => {
			state.selectedMaintenanceObjectiveItem = action.payload;
		},
	},
})

// Action creators are generated for each case reducer function
export const { setSelectedMaintenanceCommonItem, setSelectedMaintenanceObjectiveItem } = MainMaintenanceSlice.actions

export default MainMaintenanceSlice.reducer