// Tanner Fry
// tannerf1101@yahoo.com
// API calls for user settings.

import { getCorrectDomain } from "../AppSettings";

export const APIGetUserSettings = () => {
    return fetch(getCorrectDomain() + '/api/users/settings', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
        },
        body: JSON.stringify({
            userId: JSON.parse(localStorage.getItem('user')).id,
        })
    }).then(function (response) {        
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(response);
    }).then(function (data) {
        // console.log("Fetched user settings data: " + JSON.stringify(data[0]));
        return data[0];
    }).catch(function (error) {
        console.warn('Something went wrong.', error);
    });
}

export const APIPostNewUserSettings = (hardinessZone, temperatureUnit, weatherLocation) => {
    return fetch(getCorrectDomain() + '/api/users/settings/add', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
        },
        body: JSON.stringify({
            userId: JSON.parse(localStorage.getItem('user')).id,
            hardinessZone: hardinessZone,
            temperatureUnit: temperatureUnit,
            location: weatherLocation
        })
    }).then(function (response) {        
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(response);
    }).then(function (data) {
        console.log("Pushed new user settings data: " + JSON.stringify({
            userId: JSON.parse(localStorage.getItem('user')).id,
            hardinessZone: hardinessZone,
            temperatureUnit: temperatureUnit,
            location: weatherLocation
        }));
        console.log("Fetched new user settings data: " + JSON.stringify(data[0]));
        return data[0];
    }).catch(function (error) {
        console.warn('Something went wrong.', error);
    });
}

// interface IAddUserSettings {
//     userId: number,
//     hardinessZone: number,
//     temperatureUnit: string,
//     location: string
// }
export const APIPostAddUserSettings = (hardinessZone: number, temperatureUnit: string, weatherLocation: string) => {
    return fetch(getCorrectDomain() + '/api/users/settings/add', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
        },
        body: JSON.stringify({
            userId: JSON.parse(localStorage.getItem('user')).id,
            hardinessZone: hardinessZone,
            temperatureUnit: temperatureUnit,
            location: weatherLocation
        })
    }).then(function (response) {        
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(response);
    }).then(function (data) {
        console.log("Pushed new user settings data: " + JSON.stringify({
            userId: JSON.parse(localStorage.getItem('user')).id,
            hardinessZone: hardinessZone,
            temperatureUnit: temperatureUnit,
            location: weatherLocation
        }));
        console.log("Fetched new user settings data: " + JSON.stringify(data));
        return data;
    }).catch(function (error) {
        console.warn('Something went wrong.', error);
    });
}

export const APIPostUpdateUserSettings = (hardinessZone, temperatureUnit, weatherLocation) => {
    return fetch(getCorrectDomain() + '/api/users/settings/update', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
        },
        body: JSON.stringify({
            userId: JSON.parse(localStorage.getItem('user')).id,
            hardinessZone: hardinessZone,
            temperatureUnit: temperatureUnit,
            location: weatherLocation
        })
    }).then(function (response) {        
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(response);
    }).then(function (data) {
        console.log("Pushed new user settings data: " + JSON.stringify({
            userId: JSON.parse(localStorage.getItem('user')).id,
            hardinessZone: hardinessZone,
            temperatureUnit: temperatureUnit,
            location: weatherLocation
        }));
        
        console.log("Fetched new user settings data: " + JSON.stringify(data[0]));
        return data[0];
    }).catch(function (error) {
        console.warn('Something went wrong.', error);
    });
}

export const APIGetDeleteAllUserSettings = () => {
    return fetch(getCorrectDomain() + '/api/users/settings/delete-all', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
        },
    }).then(function (response) {        
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(response);
    }).then(function (data) {
        console.log("Fetched delete all user settings data: " + JSON.stringify(data[0]));
        return data[0];
    }).catch(function (error) {
        console.warn('Something went wrong.', error);
    });
}