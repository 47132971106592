// Tanner Fry
// tannerf1101@yahoo.com
// The redux slice for the menu options.

import { createSlice } from '@reduxjs/toolkit'

export interface IWeatherSlice {
	weatherSlice: {
		weatherData: any;
		currentTemp: number;
	}
}

export const WeatherSlice = createSlice({
	name: 'weatherSlice',
	initialState: {
		weatherData: [],
		currentTemp: 0,
	},
	reducers: {
		setWeatherData: (state, action) => {
			state.weatherData = action.payload;
		},
		setCurrentTemp: (state, action) => {
			state.currentTemp = action.payload;
		},
	},
})

// Action creators are generated for each case reducer function
export const { setWeatherData, setCurrentTemp } = WeatherSlice.actions

export default WeatherSlice.reducer