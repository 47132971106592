import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { ExitButton } from "../elements/Button";
import { APIGETServerNotification } from "../../api/Common";
import _settings, { getCorrectDomain } from "../../AppSettings";
import { APIGetUserPreferencesByUserId, IUserPreferences } from "../../api/APIUserPreferences";

interface IServerNotificationAPIResponse {
    response: string,
    message: {
        title: string,
        text: string
    }
}

function ServerNotification() {
    const location = useLocation();
    const locationToShowAt = [
        '/',
        '/about',
        '/dashboard',
    ]
    const [show, setShow] = useState(false);
    const [notificationTitle, setNotificationTitle] = useState('');
    const [notificationText, setNotificationText] = useState('');

    useEffect(() => {
        // Start drop animation
        const getPreferences = async () => {
            const apiResponse = await APIGetUserPreferencesByUserId();
            if (apiResponse === null) {
                return;
            }
            
            const userPrefs: IUserPreferences = apiResponse[0];
            console.log("APIGetUserPreferencesByUserId (Server notification) response: ", userPrefs);
            
            if (locationToShowAt.includes(location.pathname) && userPrefs?.notificationPopupEnabled) {
                setShow(true);
            } else {
                setShow(false);
            }
        }

        getPreferences();
    }, [location]);

    // Server Sent Events listener
    useEffect(() => {
        console.log("Connecting to server sent events...");
        
        const eventSource = new EventSource(getCorrectDomain() + _settings.API_SSE_NOTIFICTION_URL);
        eventSource.onmessage = (event) => {
            // console.log("Server sent event: ", event);
            const data:IServerNotificationAPIResponse = JSON.parse(event.data);
            // console.log("Server sent event data: ", data);
            if (data?.message) {
                console.log("Server sent event message: ", data?.message);
                setNotificationTitle(data?.message.title);
                setNotificationText(data?.message.text);
            }
        }
        eventSource.onerror = (event) => {
            // console.log("Server sent event error: ", event);
            eventSource.close();
        }
        return () => {
            console.log("Disconnecting from server sent events...");
            
            eventSource.close();
        }
    }, []);

    return (
        <>
            {show && notificationTitle !== "" && notificationText !== "" && (
                <motion.div
                    initial={{ 
                        top: '7vh', 
                        opacity: 0,
                        right: '-15vw'
                    }}
                    animate={{ 
                        position: 'fixed',
                        opacity: 1,
                        right: 0,
                        top: '7vh', 
                    }}
                    transition={{ duration: 0.75, ease: "easeInOut" }}
                    style={{
                        zIndex: 999,
                    }}
                    className="general-notification-container"
                >
                    <div className="general-notification">
                        <div className="header">
                            <h3>{notificationTitle}</h3>
                            <ExitButton handleCallback={() => setShow(false)} />
                        </div>
                        <div className="text">
                            <p>{notificationText}</p>
                        </div>
                    </div>
                </motion.div>
            )}
        </>    
    );
}

export default ServerNotification;