// Tanner Fry
// tannerf1101@yahoo.com
// API calls for user preferences.

import { ColorResult } from "react-color";
import { fetchAPIGET, fetchAPIPOSTByUserId } from "./Common";

// User Preferences
export interface IUserPreferences {
    appThemeColor: string,
    menuThemeColor: string,
    idleTimeoutEnabled: boolean,
    maintenanceTooltipEnabled: boolean,
    notificationPopupEnabled: boolean,
}
export const APIGetUserPreferences = () => {
    return fetchAPIGET('/api/userpreferences');
}
export const APIGetUserPreferencesByUserId = () => {
    return fetchAPIPOSTByUserId<IUserPreferences>('/api/userpreferences', {});
}
export const APIPOSTUserPreferences = () => {
    return fetchAPIPOSTByUserId<IUserPreferences>('/api/userpreferences/add', {});
}
export const APIPOSTUPDATEUserPreferencesIdleTimeout = (toggleChecked: boolean) => {
    let toggleCheckedNumber: number = 0;
    if (toggleChecked) {
        toggleCheckedNumber = 1;
    } else {
        toggleCheckedNumber = 0;
    }
    console.log("Toggle checked number: ", toggleCheckedNumber);
    return fetchAPIPOSTByUserId<IUserPreferences>('/api/userpreferences/update/idletimeout', {
        idleTimeoutEnabled: toggleCheckedNumber
    });
}
export const APIPOSTUPDATEUserPreferencesMenuThemeColor = (color: ColorResult) => {
    return fetchAPIPOSTByUserId<IUserPreferences>('/api/userpreferences/update/menuthemecolor', {
        menuThemeColor: `rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
    });
}
export const APIPOSTUPDATEUserPreferencesMaintenanceTooltip = (toggleChecked: boolean) => {
    let toggleCheckedNumber: number = 0;
    if (toggleChecked) {
        toggleCheckedNumber = 1;
    } else {
        toggleCheckedNumber = 0;
    }
    console.log("Toggle checked number: ", toggleCheckedNumber);
    return fetchAPIPOSTByUserId<IUserPreferences>('/api/userpreferences/update/maintenancetooltip', {
        maintenanceTooltipEnabled: toggleCheckedNumber
    });
}
export const APIPOSTUPDATEUserPreferencesNotificationPopup = (toggleChecked: boolean) => {
    let toggleCheckedNumber: number = 0;
    if (toggleChecked) {
        toggleCheckedNumber = 1;
    } else {
        toggleCheckedNumber = 0;
    }
    console.log("Toggle checked number: ", toggleCheckedNumber);
    return fetchAPIPOSTByUserId<IUserPreferences>('/api/userpreferences/update/notificationpopup', {
        notificationPopupEnabled: toggleCheckedNumber
    });
}