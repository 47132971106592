// Tanner Fry

import { useEffect, useState } from "react";
import { CONST_HOBBIES_HUNTING_REGULATIONS_SOURCES_DATA, IHuntingRegulationsSourcesProps } from "../../data/HobbiesHuntingData";
import { CustomSelectStatesField } from "../../inputs/CommonInputs";

function HobbiesHuntingLicensesAndRegulationsTable() {
    const [selectedUSState, setSelectedUSState] = useState('');
    const [huntingSeasonsRegulationsLicensesData, setHuntingSeasonsRegulationsLicensesData] = useState<IHuntingRegulationsSourcesProps[]>([]);

    useEffect(() => {
        console.log('New state selected: ' + selectedUSState);
        if (selectedUSState != '') {
            // Filter hunting seasons data by selected state
            const filteredHuntingSeasonsData = CONST_HOBBIES_HUNTING_REGULATIONS_SOURCES_DATA.filter((huntingSeason) => {
                return huntingSeason.state === selectedUSState;
            })
            console.log('Filtered hunting seasons data: ', filteredHuntingSeasonsData);
            setHuntingSeasonsRegulationsLicensesData(filteredHuntingSeasonsData);
        } else {
            // Reset hunting seasons data to default
            setHuntingSeasonsRegulationsLicensesData([]);
        }
        
    }, [selectedUSState]);

    return (
        <div className="hunting-seasons-container">
            <h2>License and Regulations {selectedUSState ? 'in ' + selectedUSState : ''} </h2>
            <CustomSelectStatesField selectedUSState={selectedUSState} setSelectedUSState={setSelectedUSState} />
            <div className="hunting-seasons-data-container">
                { huntingSeasonsRegulationsLicensesData.length > 0 && huntingSeasonsRegulationsLicensesData.map((huntingSeason) => {

                    return (
                        <div className="hunting-seasons-data" key={ huntingSeason.state }>
                            {/* <h4>{ huntingSeason.stateName }</h4> */}
                            <p>Regulations: <a href={huntingSeason.regulationSource} target="_blank">{ huntingSeason.regulationSource }</a></p>
                            <p>License: <a href={huntingSeason.licenseSource} target="_blank">{ huntingSeason.licenseSource }</a></p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default HobbiesHuntingLicensesAndRegulationsTable;