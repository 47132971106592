import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import Select from 'react-select';
import DatePicker from 'react-datepicker';  // https://www.npmjs.com/package/react-datepicker
import TimePicker from 'rc-time-picker';  // https://react-component.github.io/time-picker/

import 'react-datepicker/dist/react-datepicker.css';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import CreatableSelect from 'react-select/creatable';
import { escapeRegExpSpecialCharacters, escapeRegExpSpecialCharactersDoubleQuotesSingleQuotes } from '../Common';

export const CustomDatePickerField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props.field.name);

    return (
        <DatePicker
            {...field}
            {...props}
            dateFormat='MM-dd-yyyy'
            // dateFormat='Y-m-d\TH:i:s'
            selected={(field.value && new Date(field.value)) || null}
            onChange={value => {
                console.log("Date: ", value);
                
                setFieldValue(field.name, value);
            }}
            autoComplete='off'
        />
    );
};

export const CustomTimePickerField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props.field.name);
    var defaultTime = moment(moment().date()).hour(12).minute(30);

    // Set default value
    useEffect(() => {
        setFieldValue(field.name, defaultTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <TimePicker
            {...props}
            defaultValue={ defaultTime }
            defaultOpenValue={ defaultTime }
            placeholder="12:30"
            showSecond={ false }
            onChange={value => {
                setFieldValue(field.name, value);
            }}
            // @ts-ignore
            autoComplete='off'
        />
    );
};

export const CustomFishingCatchImageField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props.field.name);
    const [hasValue, setHasValue] = useState(false);

    return (
        <>
            <label htmlFor="catch-image-upload" className="btn-main" style={{flexBasis: "max-content"}}>Upload an image</label>
            <input id="catch-image-upload" type="file" name="image" style={{display: "none"}} onChange={(e) => {
                setFieldValue(field.name, e.target.files[0]);
                setHasValue(true);
            }} />
            { hasValue && 
                <div className="catch-image-preview-container">
                    <img src={URL.createObjectURL(field.value)} alt="catch" />
                </div> 
            }
        </>
    );
};

export const CustomNumberField = (props) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props.field.name);

    // Use value to make this a controlled componenet, which is when form data is handled by the component's state.
    return (
        <div className='number-container'>
            <input
              {...props}
              placeholder="Enter your number"
              type="number"
              value={field.value}
              onChange={e => {
                e.preventDefault();
                const { value } = e.target;
                const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                if (regex.test(value.toString())) {
                  setFieldValue(field.name, value);
                }
              }}
              onBlur={props.handleBlur}
            />
        </div>
    )
}
export const CustomPositiveNumberField = (props) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props.field.name);

    // Use value to make this a controlled componenet, which is when form data is handled by the component's state.
    return (
        <div className='number-container'>
            <input
              {...props}
              placeholder="Enter your number"
              type="number"
              value={field.value}
              onChange={e => {
                e.preventDefault();
                const { value } = e.target;
                const regex = /^[1-9][0-9]*$/;
                if (regex.test(value.toString())) {
                  setFieldValue(field.name, value);
                }
              }}
              onBlur={props.handleBlur}
            />
        </div>
    )
}

export const CustomOTPField = (props) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props.field.name);

    // Use value to make this a controlled componenet, which is when form data is handled by the component's state.
    return (
        <div className='number-container'>
            <input
              {...props}
              placeholder="Enter your number"
              type="number"
              value={field.value}
              onChange={e => {
                e.preventDefault();
                const { value } = e.target;
                // const regex = /^([0-9]|[1-9][0-9]{1,3})$/;
                setFieldValue(field.name, value);
                // const regex = /^-?[\d]+$/;
                // if (regex.test(value.toString())) {
                //   setFieldValue(field.name, value);
                // }
              }}
              onBlur={props.handleBlur}
            />
        </div>
    )
}

export const CustomSelectField = (props) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props.field.name);

    // Use value to make this a controlled componenet, which is when form data is handled by the component's state.
    return (
        <Select
            { ...props }
            className={'select-container ' + props?.classSuffix}
            classNamePrefix='select'
            onChange={value => {
                setFieldValue(field.name, value);
                props?.onChangeCallback(value);
            }}
            onBlur={props.handleBlur}
        />
    );
}

export const CustomSelectCreatableField = (props) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props.field.name);

    // Use value to make this a controlled componenet, which is when form data is handled by the component's state.
    return (
        <CreatableSelect 
            {...props}
            className={'custom-select-creatable-container ' + props?.classSuffix}
            classNamePrefix='custom-select-creatable'
            isMulti 
            options={props.options}
            onChange={(value: [{label: string, value: string, __isNew__: boolean}]) => {
                console.log("Value: ", value);
                
                if (value.length > 0) {
                    // Loop through all values and escape regex
                    const escapedValues = value.map((v) => {
                        const valueLabel = escapeRegExpSpecialCharactersDoubleQuotesSingleQuotes(v.label);
                        const valueValue = escapeRegExpSpecialCharactersDoubleQuotesSingleQuotes(v.value);
                        return {label: valueLabel, value: valueValue, __isNew__: v.__isNew__};
                    });
                    setFieldValue(field.name, escapedValues);
                    if (props?.onChangeCallback) {
                        props?.onChangeCallback(value);
                    }
                    
                } else {
                    setFieldValue(field.name, value);
                    if (props?.onChangeCallback) {
                        props?.onChangeCallback(value);
                    }
                }
            }} 
        />
    );
}

export const CustomSelectCreatableNotMultiField = (props) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props.field.name);

    // Use value to make this a controlled componenet, which is when form data is handled by the component's state.
    return (
        <CreatableSelect 
            {...props}
            className={'custom-select-creatable-container ' + props?.classSuffix}
            classNamePrefix='custom-select-creatable'
            options={props.options}
            onChange={(value: {label: string, value: string}) => { 
                console.log("Value: ", value);
                           
                // Escape regex
                const valueLabel = escapeRegExpSpecialCharactersDoubleQuotesSingleQuotes(value.label);
                const valueValue = escapeRegExpSpecialCharactersDoubleQuotesSingleQuotes(value.value);
                setFieldValue(field.name, {label: valueLabel, value: valueValue});
                if (props?.onChangeCallback) {
                    props?.onChangeCallback(value);
                }
            }} 
        />
    );
}

export const CustomSearchBar = (props) => {

    return (
        <input 
            className={'search-bar ' + props.classSuffix} 
            placeholder={props.placeholder}
            onChange={(e) => {
                props?.onChangeCallback(e.target.value);
            }}
        />
    );
}



export const CustomPasswordField = (props) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props.name);
    const [showHidePassword, setShowHidePassword] = useState(false);

    // Use value to make this a controlled componenet, which is when form data is handled by the component's state.
    return (
        <div className='password-container'>
            {/* Password */}
            <input
                type={showHidePassword ? 'text' : 'password'}
                { ...props }
                onChange={value => {
                    setFieldValue(field.name, value.target.value);
                }}
            />
            {/* Checkmark */}
            {/* @ts-ignore */}
            <div className='show-hide' for='checkbox-input'>
                <input
                    id='checkbox-input'
                    type='checkbox'
                    checked={showHidePassword ? false : true}
                    onChange={() => {
                        setShowHidePassword(!showHidePassword);
                    }}
                />
                <span className='checkmark'></span>
            </div>
        </div>
    )
}

export const CustomSelectStatesField = ({setSelectedUSState, selectedUSState}) => {
    
    return (
        <select 
            className="select-main"
            name="state" 
            value={ selectedUSState } 
            onChange={ (e) => {
                setSelectedUSState(e.target.value);
                e.target.size = 1;
                e.target.blur();
            }}
            onFocus={(e) => {
                // Set size to 5 to limit options
                e.target.size = 5;
            }}
            onBlur={(e) => {
                // Set size to 1 to hide options
                e.target.size = 1;
            }}
        >
            <option value="">Select a State</option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
        </select>
    )
}